import React, { useState, useEffect } from "react";
import ProductCard from "../ProductCard";
import { Button, Pagination } from "@mui/material";
import "./style.css";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import UseFetch from "../../hook/useFetch";
import { useLocation } from "react-router";

export default function AllProducts({ souscategName, categName, familyName }) {
  const location = useLocation(); // Utiliser useLocation pour obtenir l'URL courante

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page')) || 1; // Extraire le numéro de page de l'URL
    setCurrentPage(page);
  }, [location.search]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [sortBy, setSortBy] = useState("nameAsc");
  const { data, loading } = UseFetch("https://saveurs-fatma-backend.vercel.app/api/article");
  const produits = Array.from(data);

  const handleSortToggle = () => {
    setSortBy(sortBy === 'priceAsc' ? 'priceDesc' : 'priceAsc');
  };

  useEffect(() => {
    const updateItemsPerPage = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage(20);
      } else if (windowWidth >= 700) {
        setItemsPerPage(20);
      } else {
        setItemsPerPage(16);
      }
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  // Filtrer les produits en fonction des critères
  const filteredProducts = familyName === "collection" ? produits : produits.filter((produit) => {
    const matchesCategorie = !categName || categName === produit.categorie;
    const matchesFamille = !familyName || familyName === produit.famille;
    const matchesSousCategorie = !souscategName || souscategName === produit.subcategorie;

    return matchesCategorie && matchesFamille && matchesSousCategorie;
  });

  const indexOfLastCard = currentPage * itemsPerPage;
  const indexOfFirstCard = indexOfLastCard - itemsPerPage;
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  let sortedProducts = [...filteredProducts]; // Copie du tableau pour ne pas modifier l'original
  if (sortBy === "priceAsc") {
    sortedProducts.sort((a, b) => (a.prix > b.prix ? 1 : (b.prix > a.prix ? -1 : 0)));
  } else if (sortBy === "priceDesc") {
    sortedProducts.sort((a, b) => (a.prix < b.prix ? 1 : (b.prix < a.prix ? -1 : 0)));
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', newPage.toString());
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const currentCards = sortedProducts.slice(
    indexOfFirstCard,
    indexOfLastCard
  );

  return loading ? (
    <div className="loader"></div>
  ) : (
    <div>
      <div className="triDiv">
        <Button
          sx={{ fontFamily: 'Nunito', letterSpacing: '1px', fontWeight: '600', fontSize: { lg: '12px', md: '12px', sm: '12px', xs: '10px' } }}
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => handleSortToggle()}
          endIcon={<SwapVertIcon />}
        >
          Prix
        </Button>
      </div>

      <div className="allProduct">
        {currentCards.map((produit, index) => (
          <ProductCard
            key={produit.article}
            index={index}
            article={produit.article}
            prix={produit.prix}
            prixPiece={produit.prixPiece}
            nbrPiece={produit.nbrPiece}
            famille={produit.famille}
            reference={produit.ref}
            stock={produit.stock}
          />
        ))}
      </div>

      {totalPages > 1 && (
        <div className="pagination">
          <Pagination
            count={totalPages}
            page={currentPage}
            shape="rounded"
            variant="outlined"
            onChange={(event, newPage) => handlePageChange(newPage)}
            color="secondary"
            size="small"
          />
        </div>
      )}
    </div>
  );
}
