import "./style.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material";

export default function BestProductCard({
  famille,
  article,
  nbrPiece,
  index,
  reference,
  prix,
  prixPiece,
  stock
}) {
  const articleName = article.split('-')[1];
  const articlImg = article.replace(/-/g, ' ');
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 900,
        lg: 1200,
      },
    },
  });

  
  return (
    <ThemeProvider theme={customTheme}>
      <div className="cardBest">
        <Card
          className="card"
          sx={{
            height: { lg: "300px", md: "300px", sm: "240px", xs: "220px" },
            width: { lg: "18vw", md: "22.5vw", sm: "21vw", xs: "41vw" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 1
          }}
          elevation={3}
        >

          <a className="cardMediaLink" href={`/produit/${famille}/${articleName}`}>
            
              <img
                className="BestcardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/saveurs-fatma.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(articlImg)}.jpg?alt=media`}
                title={articleName}
                alt={articleName}
              />
          
          </a>
         
          <CardContent
            sx={{
              borderTop: 'solid rgba(0, 0, 0, 0.190) 1px',
              width: "95%",
              height: "auto",
              position: 'absolute',
              bottom: 0,
              padding: 1,
              marginTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column',alignItems:'center',width:'100%' }}>
              <span className="articleDetail" style={{ fontWeight: 'bold' }}>
                {articleName}
              </span>
              <span className="articleDetail">
              
              </span>
         
              {prix===0?(  <span className="articleDetail" >
                {prixPiece} TND / Piéce
              </span>):(
                             <span className="articleDetail" >
                             {prix} TND / kg
                           </span>)}
            </div>
           
          </CardContent>
        </Card>
      </div>
    </ThemeProvider>
  );
}
