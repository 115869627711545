import React from "react";
import Slider from "react-slick";
import { Button } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo from "../../Images/logo.png";
import coffret from "../../Images/coffret.jpg"
import coffret2 from "../../Images/coffret2.jpg"
import ScrollToTop from "../../Components/scrollpageUp";
import NewHome from '../../Components/NouveauteHome';
import { useInView } from "framer-motion";
import { useRef } from "react";
import "./style.css";

export default function Home() {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const isInView = useInView(ref1, { once: true });
  const isInView2 = useInView(ref2, { once: true });
  const settings = {
    focusOnSelect: true,
    pauseOnHover: false,
    infinite: true,
    speed: 1100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: false,
    arrows: false,
    dots: true,
  };

  return (
    <div className="home">
      <ScrollToTop />
      <div className="carouselHome">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div key={index} className="slide-item">
              <img
                src={require(`../../Images/${slide.img}.jpg`)}
                alt={`Slide ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className="descreption" ref={ref1} style={{ 
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }}>
        <img src={logo} alt="logo" className="logoHome" />
        <p>
          <span>Saveurs de Fatma </span>est l'incarnation d'une gastronomie
          raffinée et l'essence d'un héritage transmis avec passion.
          <strong> Fondée en 2007 à Sfax, par Madame Fatma Chaabouni,</strong>{" "}
          cette pâtisserie fine est le témoin d'une tradition riche et fidèle,
          offrant une expérience culinaire empreinte de subtilité et
          d'intemporalité. Saveurs de Fatma perpétue avec élégance un
          savoir-faire authentique, reflétant la richesse de son histoire et la
          finesse de ses créations
        </p>
      </div>
      <NewHome/>
      <div className="coffretHome" ref={ref2} style={{ 
            transform: isInView2 ? "none" : "translateX(-200px)",
            opacity: isInView2 ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.6s"
          }}>
        <img src={coffret} alt="coffret"  />
        <div>
          <h1>Pour Vos Évenements</h1>
          <p>Découvrez nos coffrets et nos séléctions</p>
          <Button
                        sx={{
                          marginTop: 5,
                          paddingX:{
                            lg: 5,
                            md: 3,
                            sm: 3,
                            xs: 2,
                          },
                          fontFamily: "Nunito",
                          fontSize: {
                            lg: "14px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                        }}
                        variant="contained"
                        color="secondary"
                        size="small"
                      >
                        découvrir
                      </Button>
        </div>
        <img src={coffret2} alt="coffret"  />
      </div>
    </div>
  );
}
const slides = [
  {
    img: "ban1",
    descreption: "Des saveurs qui racontent une histoire",
  },
  {
    img: "ban2",
    descreption: "Partagez des moments gourmands en famille ou entre amis",
  },
  {
    img: "ban3",
    descreption: "Goûtez à l'excellence à chaque plat",
  },
  {
    img: "ban4",
    descreption: "Goûtez à l'excellence à chaque plat",
  },
  {
    img: "ban5",
    descreption: "Goûtez à l'excellence à chaque plat",
  },
];
