/* eslint-disable react-hooks/exhaustive-deps */
import './style.css';
import React from 'react';
import AllProducts from '../../Components/allProducts';
import ScrollToTop from '../../Components/scrollup';
import ScrollPage from '../../Components/scrollpageUp'
export default function Products() {
  const [path1, setPath1] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [path2, setPath2] = React.useState('');

  React.useEffect(() => {
    let pathName = window.location.pathname;
    let pathe = pathName.replace(/%20/g, ' ');
 
    let newPath1 = pathe.split('/')[2];
    let newPath2 = pathe.split('/')[3];
    setPath1(newPath1);
    setPath2(newPath2);
  }, []);
  console.log(path1)
  return (
    <div className='products' id='produits'>
     <div className="productCouverture">
      {!path1?(<div
                className="slideText"
                style={{
                  backgroundImage: `url(${`https://firebasestorage.googleapis.com/v0/b/saveurs-fatma.appspot.com/o/couverturePics%2FCOLLECTION.jpg?alt=media`})`,
                }}
              >
       <div>  <p>NOS GAMMES</p>
       </div> </div>):(
       <div
                className="slideText"
                style={{
                  backgroundImage: `url(${`https://firebasestorage.googleapis.com/v0/b/saveurs-fatma.appspot.com/o/couverturePics%2F${decodeURIComponent(path1)}.jpg?alt=media`})`,
                }}
              >
       <div>   <p>{decodeURIComponent(path1)}</p>
       </div> </div>)}
                
                
                </div>
     
      <ScrollPage/>
      <div className='product'>
        <ScrollToTop/>
        <div className='nav'>
       
        </div>
        <div className='productContain'>
          { path1 ? (
          <span className='navbarCollection'>
          collection
          {path1 && <a href={`/collection/${decodeURIComponent(path1)}`}>{` / ${decodeURIComponent(path1)}`} </a>}  
          </span>
          ) : (
            <h2 className='pathTitle'>collection</h2>
          )}
          <AllProducts  familyName={decodeURIComponent(path1?path1:'collection')} />
        </div>
      </div>
    </div>
  );
}
