import "./style.css";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import { Button, createTheme, ThemeProvider } from "@mui/material";
import { useInView } from "framer-motion";
import { useRef } from "react";
export default function ProductCard({
  
  famille,
  article,
  nbrPiece,
  index,
  reference,
  prix,
  brand,
  stock,
  prixPiece
}) {
  const ref2 = useRef(null);
  const isInView2 = useInView(ref2, { once: true });
  const articleName = article.split('-')[1];
  const articlImg = article.replace(/-/g, ' ');
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1080,
        lg: 1280,
      },
    },
    palette: {
      primary: {
         
              main: '#859e96',
              dark: '#af7c80',
              contrastText: '#fff',
        
      },
      secondary: {
         
        main: '#af7c80',
        dark: '#859e96',
        contrastText: '#fff',
  
  },
    },
  });

  const [open, setOpen] = React.useState(false);
  const addToCart = () => {
    const existingCart = JSON.parse(localStorage.getItem('cart')) || [];
    let productExists = false;

    existingCart.forEach(product => {
      if (product.name === articleName) {
        productExists = true;
        product.quantite += 500; // Incrémenter la quantité si le produit existe déjà
        
      }
    });

    if (!productExists) {
      let price =prix;
     
      const product = {
        family: famille,
        name: articleName,
        img: articlImg,
        price: price.toFixed(3),
        brand: brand,
        reference: reference,
        quantite: 500 ,
      };
      
      existingCart.push(product);
      
    }
    

    localStorage.setItem('cart', JSON.stringify(existingCart));
    handleClick();
  };
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose =() => {
    window.location.reload()
    setOpen(false);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const cartItems = JSON.parse(localStorage.getItem("cart"));

  const cartContain = cartItems ? cartItems.length : 0;
  return (
    <ThemeProvider theme={customTheme} >
         <Card
        
        ref={ref2}
        style={{
          transform: isInView2 ? "none" : "translateY(-250px)",
          opacity: isInView2 ? 1 : 0,
          transition: `all 0.8s cubic-bezier(0.17, 0.55, 0.55, 1) 0.${6 + index}s`
        }}
        className="card"
        sx={{
          height: { lg: "300px", md: "300px", sm: "260px", xs: "220px" },
          width: { lg: "18vw", md: "22.5vw", sm: "22.5vw", xs: "38vw" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 2
        }}
        elevation={3}
        >
        
      
       
        <a className="cardMediaLink" href={`/produit/${famille}/${articleName}`}>
              <img
                className="cardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/saveurs-fatma.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(articlImg)}.jpg?alt=media`}
                title={articleName}
                alt={articleName}
                onClick={() => handleOpenDialog()}
              />
              </a>
             <Dialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      sx={{ zIndex: 9999 }}
                    >
                       <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[700],
                zIndex:9999999999
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
                        <img
                className="cardMediaDialog"
                src={`https://firebasestorage.googleapis.com/v0/b/saveurs-fatma.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(articlImg)}.jpg?alt=media`}
                title={articleName}
                alt={articleName}
                       style={{
                          maxWidth: "60vw",
                          maxHeight: "80vh",
                          objectFit: "contain",
                          zIndex: 9999999,
                        }}
                        />
                    </Dialog>
         
          <CardContent
            sx={{
              borderTop: 'solid rgba(0, 0, 0, 0.190) 1px',
              width: "95%",
              height: "auto",
              position: 'absolute',
              bottom: 0,
              padding: 1,
              marginTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column',alignItems:'center',width:'100%' }}>
              <span className="articleDetaile" style={{ fontWeight: '600',fontFamily:'Nunito' }}>
                {articleName} 
              </span>
             
              <span className="articleDetaile2" > {nbrPiece===0?(<></>):(<>
            {nbrPiece} piéce / kg  &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; </>)}  REF:{reference}
              </span>
             
              {prix===0?(  <span className="articleDetaile2" >
                {prixPiece} TND / Piéce
              </span>):(
                             <span className="articleDetaile2" >
                             {prix} TND / kg
                           </span>)}
     
         {stock===0||famille==="CHOCOLAT"||famille==="SALÉ"||famille==="MACARONS"?(<></>):(
              <AddShoppingCartIcon
              
              sx={{ color: '#859e96', cursor: 'pointer',fontSize:{lg:'28px'},position:'absolute',bottom:'5px',right:'5px' }}
              titleAccess={`Ajouter ${articleName} au panier`}
              onClick={addToCart}
            />
         )}
         </div>
            <Dialog open={open}   sx={{zIndex:'999999999999'}}>
            
            <div className="card">
        <Card
          className="card"
          sx={{
            height: { lg: "600px", md: "450px", sm: "550px", xs: "480px" },
            width: { lg: "20vw", md: "25vw", sm: "35vw", xs: "60vw" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2
          }}
          elevation={3}
        >
          <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[700],
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
         
            <img
                className="cardMedia"
                src={`https://firebasestorage.googleapis.com/v0/b/saveurs-fatma.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(articlImg)}.jpg?alt=media`}
                title={articleName}
                alt={articleName}
              />
        
          <CardContent
            sx={{
              borderTop: 'solid rgba(0, 0, 0, 0.190) 1px',
              width: "95%",
              height: "auto",
              position: 'absolute',
              bottom: 0,
              padding: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column',width:'90vw',padding:'5px' }}>
              <span className="articleDetaile" style={{ fontWeight: 'bold' }}>
                {articleName}
              </span>
              <span className="articleDetaile">
                {brand}
              </span>
              {prix===0?(  <span className="articleDetaile">
                {prixPiece} TND / Piéce
              </span>):(
                           <span className="articleDetaile">
                           {prix} TND / KG
                         </span>)}
  
            
             
              
               <span className="articleDetaile" style={{fontWeight:'bold'}}>
              IL Y A {cartContain} ARTICLE(S) DANS VOTRE PANIER
            </span>
              
               <Button variant="contained" fullWidth color="secondary" sx={{ fontWeight: 'bold',fontSize:{lg:'14px',md:'12px',sm:'10px',xs:'10px'},marginTop:'10px' }} onClick={handleClose} > continuer mes achats  </Button>
            <a href="/panier">   <Button fullWidth variant="contained" color="primary" sx={{ fontWeight: 'bold',fontSize:{lg:'14px',md:'12px',sm:'10px',xs:'10px'},marginTop:'10px' }} > finaliser ma commande  </Button></a>
            </div>
            </CardContent>
            </Card>
      </div>
      </Dialog>
          </CardContent>
        </Card>
    </ThemeProvider>
  );
}
